.link {
  font-size: 1.6rem;
  color: #3273dc;
}

.link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.list {
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style-type: none;
}

.list li {
  padding-left: 1rem;
  padding-right: 1rem;
}

.list li:last-child {
  padding-right: 0;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 6.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  max-width: 76.8rem;
  margin: auto;
  margin-bottom: 5rem;
}

.title {
  margin: 0;
  font-size: 2.4rem;
}
